/* * @Descripttion: 预警中心 * @version: 2.2.2 * @LastEditors: 刘志坤 * @LastEditTime: 2022-04-6 09:40 */ // 刘志坤
V2.3.0
<template>
  <section style="margin: 0 24px">
    <section class="form-style">
      <a-form layout="inline">
        <a-form-item label="" @click="clickFocus">
          <a-input
            class="form-input_365px"
            allowClear
            :maxlength="20"
            v-model:value="searchVal"
            placeholder="请输入印章名称、申请人或手机号"
            ref="inputbox"
          >
            <template #prefix>
              <img src="@/assets/svg/search.svg" />
            </template>
          </a-input>
        </a-form-item>
        <a-form-item label="预计用印日期：" name="enterTime">
          <!-- <a-range-picker :disabledDate="disabledDate" separator="→" allowClear v-model:value="enterTime"> -->
          <a-range-picker separator="→" allowClear v-model:value="enterTime">
            <template #suffixIcon>
              <CalendarOutlined />
            </template>
          </a-range-picker>
        </a-form-item>
        <a-form-item class="form-btns">
          <a-button @click="reset">重置</a-button>
          <a-button @click="searchList" class="form-btn">查询</a-button>
        </a-form-item>
      </a-form>
    </section>
    <section class="table-style">
      <div class="table-head">
        <span class="title">用印列表</span>
      </div>
      <a-table
        :columns="warningColumns"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="tableLoading"
        @change="handleTableChange"
        rowKey="messagePushId"
        ref="tableRef"
        :scroll="{ x: 400 }"
      >
        <template #id="{ index }">{{ (pagination.index - 1) * pagination.pageSize + index + 1 }}</template>
        <template #custodianName="{ text }"
          ><span :title="text">{{ text ?? '-' }}</span></template
        >
        <template #sealName="{ text }">
          <span v-if="selfSealRelList?.length == null" :title="text">{{ text ?? '-' }}</span>
          <span v-else :title="text">{{ text ?? '-' }}</span>
        </template>
        <template #fileName="{ text }">
          <span v-if="text == '' || !text" :title="text">-</span>
          <span v-else :title="text">{{ text }}</span>
        </template>
        <template #content="{ text }">
          <span :title="text">{{ text ?? '-' }}</span>
        </template>
        <template #sealTime="{ text }">
          <span :title="text">{{ text ?? '-' }}</span>
        </template>
        <template #action="{ record }">
          <a @click="openCheckModal(record)" class="table-text-color">查看</a>
        </template>
      </a-table>
    </section>
    <cmodal
      :modalKey="modalKey"
      v-model:visible="cmvisible"
      :currentLoading="currentLoading"
      :current="current"
      :hasFoot="false"
    />
  </section>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import { warningColumns } from './columns'
import cmodal from './components/checkModal'
import tableHeader from '@/views/components/tableHeader'
import { getSelfHelpList, getSelfHelpDetail } from '@/apis/selfHelp'
import { getProcessStaffList } from '@/apis/businessManage'
import { Modal } from 'ant-design-vue'
import { cmsNotice, setTablePageFooterStyle } from '@/utils/utils'
import { warningStatusObj, paginationParams } from '@/utils/constData'
import { PlusOutlined, CalendarOutlined } from '@ant-design/icons-vue'
import { useRouter } from 'vue-router'
import moment from 'moment'

export default defineComponent({
  name: 'warningCenter',
  components: {
    cmodal,
    // tableHeader,
    // PlusOutlined,
    CalendarOutlined
  },
  setup() {
    const formState = reactive({})
    const inputbox = ref(null)
    const tableRef = ref()
    const formData = ref({})
    const router = useRouter()
    const state = reactive({
      searchVal: undefined,
      enterTime: [],
      warningColumns,
      dataSource: [],
      visible: false,
      cmvisible: false,
      current: {},
      currentLoading: false,
      pagination: {
        ...paginationParams
      },
      actionType: 'add',
      loading: false,
      processStaffList: [],
      tableLoading: true,
      currentNum: 1
    })
    // 日期选择器：当天之后不可选
    // const disabledDate = (current) => {
    //   return current && current > moment().endOf("day");
    // }

    // 获取列表
    const getList = async () => {
      state.tableLoading = true
      const params = {
        search: state.searchVal,
        startTime: '',
        endTime: '',
        pageIndex: state.pagination.current,
        pageSize: state.pagination.pageSize
      }
      if (!state.enterTime.length) {
        params.startTime = ''
        params.endTime = ''
      } else {
        params.startTime = moment(state.enterTime[0]).format('YYYY-MM-DD')
        params.endTime = moment(state.enterTime[1]).format('YYYY-MM-DD')
      }
      const res = await getSelfHelpList(params)
      console.log(res, '111')
      state.dataSource = res.data
      state.pagination.total = res.totalItem
      state.pagination.index = res.pageIndex
      state.tableLoading = false
      setTablePageFooterStyle(tableRef)
    }
    // 重置
    const reset = () => {
      state.searchVal = ''
      state.enterTime = []
      state.pagination.current = 1
      getList()
    }

    const searchList = () => {
      // 清空前后空格
      if (state.searchVal) {
        state.searchVal = state.searchVal.replace(/\s*/g, '')
      }
      console.log(state.enterTime)
      state.pagination.current = 1
      getList()
    }
    const handleTableChange = ({ current, pageSize }) => {
      state.dataSource = []
      state.pagination.current = current
      state.currentNum = current
      state.pagination.pageSize = pageSize
      getList()
    }
    const openCheckModal = async current => {
      state.currentLoading = true
      const res = await getSelfHelpDetail(current.id)
      console.log(res.data, state.modalKey)
      state.current = res.data
      state.cmvisible = true
      state.currentLoading = false
    }
    // 搜索框点击
    const clickFocus = () => {
      inputbox.value.focus()
    }
    onMounted(() => {
      getList()
    })

    return {
      ...toRefs(state),
      openCheckModal,
      searchList,
      handleTableChange,
      warningStatusObj,
      tableRef,
      clickFocus,
      inputbox,
      reset
    }
  }
})
</script>

<style lang="scss" scoped>
::v-deep .ant-select {
  .ant-select-selector {
    height: 36px;
    border-radius: 4px;
    line-height: 36px;
  }
  .ant-select-selection-placeholder {
    line-height: 36px;
  }
  .ant-select-selection-item {
    line-height: 36px;
  }
}
.dotted {
  width: 7px;
  height: 7px;
  margin-right: 5px;
  display: inline-block;
  border-radius: 50%;
  transform: translateY(-1px);
}
</style>
